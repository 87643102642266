import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";

//nav components
import Home from './pages/home/Home';
import Signin from './pages/signin/Signin';
import Privacy from './pages/privacy/Privacy';
import Navbar from './components/Navbar';
import Guide from './pages/guide/Guide';
import Articles from './pages/articles/Articles';
import Progress from './pages/progress/Progress';
import Footer from './components/Footer';

// loading component for suspense fallback
const Loader = () => (
  <div className="container-xxl App px-4 py-5 my-5 text-center">
    <div className='row App-logo-container' style={{alignContent: 'center'}}>
      <img src="/digital_spa_logo_192.png" className="App-logo" alt="logo" style={{height:80, width:100}}/>
      <div>loading...</div>
    </div>
  </div>
);

function App() {

  return (
    <Suspense fallback={<Loader />}>

      <div className='App'>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/guide" element={<Guide />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/progress" element={<Progress/>} />
          </Routes>
          <Footer/>
        </BrowserRouter>
      </div>
    </Suspense>
  );
}

export default App;
