import React from "react";
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import { useTranslation } from 'react-i18next';

export default function Footer() {

    const { t } = useTranslation();

    return (
        <div className={styles.without_borders + " container-fluid"}>
            <div className="mt-4 border-top" />
            <footer className={styles.footer_container + " pt-5"} >
                <div className="row">
                    <div className="col-6 col-md-3 mb-3"> {/*mb-3 -> Margin bottom 1 spacer */}
                        <h5>{t('footer.links')}</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 pt-2 text-muted">{t('footer.homePage')}</Link></li>
                            <li className="nav-item mb-2"><Link to="/progress" className="nav-link p-0 pt-2 text-muted">{t('navBar.tab1')}</Link></li>
                            <li className="nav-item mb-2"><Link to="/progress" className="nav-link p-0 pt-2 text-muted">{t('navBar.tab2')}</Link></li>
                            <li className="nav-item mb-2"><Link to="/progress" className="nav-link p-0 pt-2 text-muted">{t('navBar.tab3')}</Link></li>
                            <li className="nav-item mb-2"><Link to="/articles" className="nav-link p-0 pt-2 text-muted">{t('navBar.tab4')}</Link></li>
                            <li className="nav-item mb-2"><Link to="/progress" className="nav-link p-0 pt-2 text-muted">{t('navBar.tab5')}</Link></li>
                        </ul>
                    </div>

                    <div className="col-6 col-md-3 mb-3">
                        <h5>{t('footer.legal.title')}</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><Link to="/progress" className="nav-link p-0 pt-2 text-muted">{t('footer.legal.use')}</Link></li>
                            <li className="nav-item mb-2"><Link to="/progress" className="nav-link p-0 pt-2 text-muted">{t('footer.legal.privacy')}</Link></li>
                            <li className="nav-item mb-2"><Link to="/progress" className="nav-link p-0 pt-2 text-muted">{t('footer.legal.cookies')}</Link></li>
                        </ul>
                    </div>

                    <div className="col-md-4 offset-md-1 mb-3">
                        <form>
                            <h5>{t('footer.contact')}</h5>
                            <p>dolor.pediatria@uma.es</p>
                        </form>
                        <div className="mt-5"> 
                            <img src="/digital_spa_logo_512.png" className={styles.footer_logo} alt="logo" />
                            <h5>{t('appName')}</h5>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">

                    <p>{t('generic.developedby')} <a href="https://www.linkedin.com/in/yelotmany/">Youssef El Otmany</a> </p>
                    <p>© {(new Date().getFullYear())} {t('footer.rightsReserved')}</p>
                    
                    {/*
                    <ul className="list-unstyled" style={{width: "32px"}}>
                        <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#twitter" /></svg></Link></li>
                        <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#instagram" /></svg></Link></li>
                        <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#facebook" /></svg></Link></li>
                    </ul>
                    */}
                </div>
            </footer>
        </div>
    )
}