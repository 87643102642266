import styles from "./Guide.module.css"
import { Link } from 'react-router-dom';
import React, { useLayoutEffect } from 'react'

export default function Guide() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (

    <div className="px-4 py-5 my-5 text-center">
      <div style={{height: "50px"}}/>
      <img className="d-block mx-auto mb-4" src="/build.svg" alt="" width="100" height="100"/>

        <h3>Página en desarrollo</h3>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">Esta página se encuentra en desarrollo actualmente, pronto estará lista para ser utilizada.</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">  
            <Link to="/" type="button" className="btn btn-outline-secondary btn-lg px-4">Ir a inicio</Link>
          </div>
        </div>
    </div>
  )
}
