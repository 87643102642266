import React, { useLayoutEffect } from 'react';
import styles from './Articles.module.css';
import { useTranslation } from 'react-i18next';

export default function Articles() {
    const { t } = useTranslation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="container-xxl pt-4">
            <div style={{height: 64}}/>
            <div className={styles.screen_title}>
                <h1>{t('articles.screenTitle')}</h1>
            </div>
            <div className={"row mx-2 " + styles.article_container}>
                <div className={"col-md-4 row"}>
                    <div className={styles.image_container}>
                        <img className={styles.article_image} src="/im_article1.png" alt='article 1' />
                        <div className={styles.article_image_text_overlay}>2023</div>
                    </div>
                </div>
                <div className={"col-md-8 " + styles.text_container}>
                    <div className={styles.row1_column1_container}>
                        <a className={styles.magazine_text}>Digital Health</a>
                        <h1 className={styles.article_title}>
                            {t('articles.article1.title')}
                        </h1>
                        <p className={styles.article_subtitle}>
                            <b className={styles.authors_word}>{t('articles.authors')}</b>: {t('articles.article1.subtitle')}
                        </p>
                        <a href="https://journals.sagepub.com/doi/10.1177/20552076231219490" target="_blank">
                            <div className={styles.section_button}  >
                                <a className={styles.read_article_button}>{t('articles.readArticle')}</a>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="9" viewBox="0 0 25 9" fill="none">
                                    <path d="M24.4243 4.92426C24.6586 4.68995 24.6586 4.31005 24.4243 4.07573L20.6059 0.257357C20.3716 0.0230428 19.9917 0.0230428 19.7574 0.257357C19.523 0.491672 19.523 0.871571 19.7574 1.10589L23.1515 4.5L19.7574 7.89411C19.523 8.12843 19.523 8.50832 19.7574 8.74264C19.9917 8.97695 20.3716 8.97695 20.6059 8.74264L24.4243 4.92426ZM5.24537e-08 5.1L24 5.1L24 3.9L-5.24537e-08 3.9L5.24537e-08 5.1Z" fill="#4BA2D0"/>
                                </svg>
                            </div>
                        </a>
                        
                    </div>
                </div>
                
            </div>
            <div style={{height: 200}}/>
        </div>
    );
}
