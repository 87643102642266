import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation} from 'react-i18next';

import styles from './Navbar.module.css'

export default function Navbar() {

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleCollapse();
  };

  const handleCollapse = () => {
    console.log("handleCollapse");
    var nav = document.getElementById("main-nav");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };

  return (
      <nav className={styles.nav + " navbar fixed-top navbar-expand-md"}>
        <div className='container-fluid'>
          <Link className={styles.logo_container + " navbar-brand ps-2"} to="/" onClick={handleCollapse}>
            <img src="/digital_spa_logo_192.png" alt='logo' width="40" height="40" className="pb-1" />
            <span className={styles.title + " d-inline-block align-bottom pt-1"}>
              Digital SPA
            </span>
          </Link>
          {/*toggle button for mobile nav*/}
          <button className="navbar-toggler shadow-none border-0" id="navbarBtn" type="button" data-bs-toggle="collapse"
            data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="ri-menu-line" />
          </button>
          {/* navbar links */}
          <div className="collapse navbar-collapse justify-content-end align-center" id="main-nav">
            <ul className="navbar-nav me-auto mb-lg-0">
              <NavLink className={"nav-link " + styles.nav_link} activeclassname="active" to="/progress" onClick={handleCollapse}>
                {t('navBar.tab1')}
              </NavLink>
              <NavLink className={styles.nav_link + " nav-link d-md-none d-lg-block"} activeclassname="active" to="/progress" onClick={handleCollapse}>
                {t('navBar.tab2')}
              </NavLink>
              <NavLink className={styles.nav_link + " nav-link d-md-none d-lg-block"} activeclassname="active" to="/progress" onClick={handleCollapse}>
                {t('navBar.tab3')}
              </NavLink>
              <NavLink className={styles.nav_link + " nav-link d-md-none d-lg-none d-xl-block"} activeclassname="active" to="/articles" onClick={handleCollapse}>
                {t('navBar.tab4')}
              </NavLink>
              <NavLink className={styles.nav_link + " nav-link d-md-none d-lg-none d-xl-none d-xxl-block"} activeclassname="active" to="/progress" onClick={handleCollapse}>
                {t('navBar.tab5')}
              </NavLink>
              <li className={styles.nav_link + " nav-item dropdown d-xxl-none d-none d-xl-block"}>
                <div className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {t('generic.more')}
                </div>
                <ul className="dropdown-menu">
                  <NavLink className={styles.nav_link + " nav-link"} activeclassname="active" to="/progress">
                    {t('navBar.tab5')}
                  </NavLink>
                </ul>
              </li>
              <li className={styles.nav_link + " nav-item dropdown d-xl-none d-none d-lg-block"}>
                <div className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {t('generic.more')}
                </div>
                <ul className="dropdown-menu">
                  <NavLink className={styles.nav_link + " nav-link"} activeclassname="active" to="/articles">
                    {t('navBar.tab4')}
                  </NavLink>
                  <NavLink className={styles.nav_link + " nav-link"} activeclassname="active" to="/progress">
                    {t('navBar.tab5')}
                  </NavLink>
                </ul>
              </li>
              <li className={styles.nav_link + " nav-item dropdown d-lg-none d-none d-md-block"}>
                <div className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {t('generic.more')}
                </div>
                <ul className="dropdown-menu">
                <NavLink className={styles.nav_link + " nav-link"} activeclassname="active" to="/progress">
                    {t('navBar.tab2')}
                  </NavLink>
                  <NavLink className={styles.nav_link + " nav-link"} activeclassname="active" to="/progress">
                    {t('navBar.tab3')}
                  </NavLink>
                  <NavLink className={styles.nav_link + " nav-link"} activeclassname="active" to="/articles">
                    {t('navBar.tab4')}
                  </NavLink>
                  <NavLink className={styles.nav_link + " nav-link"} activeclassname="active" to="/progress">
                    {t('navBar.tab5')}
                  </NavLink>
                </ul>
              </li>
            </ul>
            <div className="row d-flex pe-4" style={{ display: 'flex-start', alignItems: 'center', justifyContent: 'center' }}>
              <span className="material-symbols-outlined col d-none d-md-block" style={{ paddingRight: "0px" }}>
                translate
              </span>
              <select className={styles.select_text + ' col-md-auto'} style={{ paddingLeft: "4px", paddingRight: "6px" }}
                defaultValue={i18n.language}
                onChange={e =>
                  changeLanguage(e.target.value)}  >
                <option value="es">Español</option>
                <option value="en">English</option>
              </select>
            </div>
          </div>
        </div>
      </nav>

  )
}
