import styles from './Home.module.css'
import './Home.module.css'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import React, {useLayoutEffect} from 'react'

export default function Home() {

  const { t} = useTranslation(); // not passing any namespace will use the defaultNS (by default set to 'translation')

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <div className="container-xxl pt-4">
      <div style={{height: 64}}/>
      {/*Alert*/}
      <div className="alert alert-warning alert-dismissible fade show mx-2" role="alert">
        {t('projectInProgress.alertText')} <strong>dolor.pediatria@uma.es</strong>
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      {/*Section 1*/}
      <div className={"row mx-2 " + styles.row_1_container}>
        <div className={"col-md-6"}>
          <div className={styles.row1_column1_container}>
            <h1 className={styles.home_title}>
            {t('homePage.section1.title')}
            </h1>
            <p className={styles.home_description}>
              {t('homePage.section1.subtitle')}
            </p>

            <div className="row">

                <div className="col d-grid gap-2 d-sm-flex justify-content-sm-center">
                  <Link to="/progress" type="button" className={styles.know_more_button}>{t('homePage.section1.button1_text')}</Link>
                </div>

              <Link to="/progress" className={styles.see_products_button + " col-auto"}>
                {t('homePage.section1.button2_text')}
              </Link>
            </div>

          </div>

        </div>

        <div className={styles.row1_section2 + " col-md-6"}>
          <img className={styles.row1_section2_image} src="/im_home_welcome.jpg" alt='welcome' />
        </div>
      </div>


      {/*Section 2*/}
      <div className={styles.row2_container + " mx-2 row"}>
        <div className={styles.section_column1 + " col-md-6"}>
          <div className={styles.section_text_column + " column"}>
            <div className={styles.section_public}>{t('homePage.section2.audience')}</div>
            <div className={styles.section_title}>{t('homePage.section2.title')}</div>
            <div className={styles.section_divider}/>
            <div className={styles.section_subtitle}>{t('homePage.section2.subtitle')}</div>
            <div className={styles.section_button}>
              <Link to="/progress" className={styles.section_button_text}>{t('generic.know_more')}</Link>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="9" viewBox="0 0 25 9" fill="none">
                <path d="M24.4243 4.92426C24.6586 4.68995 24.6586 4.31005 24.4243 4.07573L20.6059 0.257357C20.3716 0.0230428 19.9917 0.0230428 19.7574 0.257357C19.523 0.491672 19.523 0.871571 19.7574 1.10589L23.1515 4.5L19.7574 7.89411C19.523 8.12843 19.523 8.50832 19.7574 8.74264C19.9917 8.97695 20.3716 8.97695 20.6059 8.74264L24.4243 4.92426ZM5.24537e-08 5.1L24 5.1L24 3.9L-5.24537e-08 3.9L5.24537e-08 5.1Z" fill="#4BA2D0"/>
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.section_column2 + " col-md-6"}>
          <img src="/im_section2.png" alt='adolescents' />
        </div>
      </div>

      {/*Section 3*/}
      <div className={styles.row2_container + " mx-2 row"}>
        
        <div className={styles.section_column2 + " col-md-6 order-md-last"}>
          <div className={styles.section_text_column + " column"}>
            <div className={styles.section_public}>{t('homePage.section3.audience')}</div>
            <div className={styles.section_title}>{t('homePage.section3.title')}</div>
            <div className={styles.section_divider}/>
            <div className={styles.section_subtitle}>{t('homePage.section3.subtitle')}</div>
            <div className={styles.section_button}>
              <Link to="/progress" className={styles.section_button_text}>{t('generic.know_more')}</Link>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="9" viewBox="0 0 25 9" fill="none">
                <path d="M24.4243 4.92426C24.6586 4.68995 24.6586 4.31005 24.4243 4.07573L20.6059 0.257357C20.3716 0.0230428 19.9917 0.0230428 19.7574 0.257357C19.523 0.491672 19.523 0.871571 19.7574 1.10589L23.1515 4.5L19.7574 7.89411C19.523 8.12843 19.523 8.50832 19.7574 8.74264C19.9917 8.97695 20.3716 8.97695 20.6059 8.74264L24.4243 4.92426ZM5.24537e-08 5.1L24 5.1L24 3.9L-5.24537e-08 3.9L5.24537e-08 5.1Z" fill="#4BA2D0"/>
              </svg>
            </div>
          </div>
        </div>
        
        <div className={styles.section_column1 + " col-md-6 order-md-first"}>
          <img src="/im_section3.png" alt='parents' />
        </div>
      </div>

      {/*Section 4 - Profesionals*/}
      <div className={styles.row2_container + " mx-2 row"}>
        <div className={styles.section_column1 + " col-md-6"}>
          <div className={styles.section_text_column + " column"}>
            <div className={styles.section_public}>{t('homePage.section4.audience')}</div>
            <div className={styles.section_title}>{t('homePage.section4.title')}</div>
            <div className={styles.section_divider}/>
            <div className={styles.section_subtitle}>{t('homePage.section4.subtitle')}</div>
            <div className={styles.section_button}>
              <Link to="/articles" className={styles.section_button_text}>{t('generic.know_more')}</Link>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="9" viewBox="0 0 25 9" fill="none">
                <path d="M24.4243 4.92426C24.6586 4.68995 24.6586 4.31005 24.4243 4.07573L20.6059 0.257357C20.3716 0.0230428 19.9917 0.0230428 19.7574 0.257357C19.523 0.491672 19.523 0.871571 19.7574 1.10589L23.1515 4.5L19.7574 7.89411C19.523 8.12843 19.523 8.50832 19.7574 8.74264C19.9917 8.97695 20.3716 8.97695 20.6059 8.74264L24.4243 4.92426ZM5.24537e-08 5.1L24 5.1L24 3.9L-5.24537e-08 3.9L5.24537e-08 5.1Z" fill="#4BA2D0"/>
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.section_column2 + " col-md-6"}>
          <img className={styles.rounded_image} src="/im_section4.jpg" alt='adolescents'  />
        </div>
      </div>


      {/*Section 5 - Entities */}
      <div className={styles.entities_section_container + " col- mx-2"} >
        <div className={styles.section_title}>{t('homePage.section5.title')}</div>
        <div className={styles.entities_subsection_title_container + " row"}>
          <div className={styles.entities_section_divider + " col"}/>
          <div className={styles.entities_section_subsection_title + " col"}>{t('homePage.section5.hospital_centers')}</div>
          <div className={styles.entities_section_divider + " col"}/>
        </div>
        
        <div className={styles.entities_section_images_container}>
          <div className={styles.entites_image_container}>
            <img src="/hosp_malaga.png" alt='Malaga hospital'/>
          </div>
          <div className={styles.entites_image_container}>
            <img src="/hosp_paz.jpeg" alt='La Paz hospital'/>
          </div>
          <div className={styles.entites_image_container}>
            <img src="/hosp_sant_joan.jpeg" alt='Sant Joan hospital'/>
          </div>
          <div className={styles.entites_image_container}>
            <img src="/hosp_santa_lucia.jpg" alt='Santa Lucía hospital'/>
          </div>
          <div className={styles.entites_image_container}>
            <img src="/hosp_torre.png" alt='Torrecárdenas hospital'/>
          </div>
          <div className={styles.entites_image_container}>
            <img src="/hosp_virgen.jpeg" alt='Virgen de las Nieves hospital'/>
          </div>
        </div>
        <div className={styles.entities_international_subsection_title_container + " row"}>
          <div className={styles.entities_section_divider + " col"}/>
          <div className={styles.entities_section_subsection_title + " col"}>{t('homePage.section5.international_colaborators')}</div>
          <div className={styles.entities_section_divider + " col"}/>
        </div>
        <div className={styles.entities_section_images_container}>
          <div className={styles.entites_image_container}>
            <img src="/hosp_seattle.jpeg" alt='Seattle chil. hospital'/>
          </div>
          <div className={styles.entites_image_container}>
            <img src="/kalorinska_institutet.jpeg" alt='Kalorinska Institutet'/>
          </div>
          <div className={styles.entites_image_container}>
            <img src="/wro_entity.jpg" alt='Worclaw'/>
          </div>
          <div className={styles.entites_image_container}>
            <img src="/urv.jpeg" alt='Rovira i Virgili University'/>
          </div>
        </div>
      </div>

      {/*Section 6 - Who we are */}
      <div className={styles.about_us_section_container + " mx-2 row"} >
        <div className={styles.section_column1 + " col-md-6"}>
          <div className={styles.section_text_column + " col"}>
            <div className={styles.section_title}>{t('homePage.section6.title')}</div>
            <div className={styles.section_divider}/>
            <div className={styles.section_subtitle}>{t('homePage.section6.subtitle')} <a href={t('homePage.section6.link')}>{t('homePage.section6.here')}</a> </div>
          </div>
        </div>
        <div className={styles.section_column2 + " col-md-6"}>
          <img className={styles.about_us_section_image} src="/im_team.jpg" alt='team'  />
        </div>
      </div>

      <div className={styles.section7_container + " mx-2 row"}>
        {/*Section 8 - Financied by */}
        <div className={styles.built_by_container + " col-md"} >
          <div className={styles.financied_by_title}>{t('homePage.section8.title')}</div>
          <img className={styles.about_us_section_image} src="/team_entity.png" alt='faculty'  />
        </div>

        {/*Section 7 - Financied by */}
        <div className={styles.financied_by_container + " col-md"} >
          <div className={styles.financied_by_title}>{t('homePage.section7.title')}</div>
          <img className={styles.about_us_section_image} src="/im_ministerio.png" alt='ministry of education'  />
          <div className={styles.financied_by_subtitle}>PID2021-122885OA-I00</div>
        </div>
      </div>
    </div>
  )
}
