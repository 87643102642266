import { Link } from 'react-router-dom';
import React, { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next';

export default function Progress() {

  const { t} = useTranslation(); 

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (

    <div className="px-4 py-5 my-5 text-center">
      <div style={{ height: "50px" }} />
      <img className="d-block mx-auto mb-4" src="/build.svg" alt="" width="100" height="100" />

      <h3>{t('projectInProgress.pageTitle')}</h3>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">{t('projectInProgress.pageSubtitle')}</p>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
          <Link to="/" type="button" className="btn btn-outline-secondary btn-lg px-4">{t('projectInProgress.pageButton')}</Link>
        </div>
      </div>
    </div>
  )
}