import styles from './Privacy.module.css'

import React from 'react'

export default function Privacy() {
  return (
    <div className={styles.page}>
      <div className={styles.container}>

      
      <p className={styles.title}>Política de Privacidad</p>
      <p className={styles.text}>Bienvenido a la aplicación de tratamiento de la fibromialgia para adolescentes. La privacidad de nuestros usuarios es de suma importancia para nosotros. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos la información personal que los usuarios proporcionan a través de nuestra aplicación. Al utilizar la aplicación, usted acepta los términos de esta política de privacidad. Si no está de acuerdo con nuestros términos, le recomendamos no utilizar la aplicación.</p>
      
      <p className={styles.subtitle}>Información Recopilada</p>
      <li className={styles.text}>Información de Registro: Para acceder a ciertas funcionalidades de la aplicación, los usuarios deben registrarse y proporcionar información personal como nombre, dirección de correo electrónico y fecha de nacimiento. Esta información se utiliza para crear y gestionar cuentas de usuario.</li>
      <li className={styles.text}> Datos de Salud: La aplicación permite a los usuarios registrar datos relacionados con su salud, como niveles de dolor y calidad del sueño. Estos datos se utilizan para ofrecer un tratamiento personalizado y para el seguimiento del progreso del tratamiento.</li>
      <li className={styles.text}>Comunicación con Profesionales: Si utiliza la función de comunicación con profesionales, los mensajes y la información compartida en estas conversaciones se almacenan de manera segura para el seguimiento y la atención médica.</li>

      <p className={styles.subtitle}>Uso de la Información</p>
      <li className={styles.text}>
        Utilizamos la información recopilada para brindar y mejorar nuestros servicios, personalizar la experiencia del usuario y comunicarnos con los usuarios.
      </li>
      <li className={styles.text}>
        Los datos de salud se utilizan para evaluar el progreso del tratamiento y para ofrecer recomendaciones personalizadas.
      </li>
      <li className={styles.text}>
        No compartimos información personal con terceros sin el consentimiento expreso del usuario, excepto en situaciones legales que lo requieran.
      </li>

      <p className={styles.subtitle}>Seguridad de la Información</p>
      <li className={styles.text}>Implementamos medidas de seguridad técnicas y organizativas para proteger la información personal de accesos no autorizados o pérdida.</li>
      <li className={styles.text}>Aunque nos esforzamos por mantener la seguridad de los datos, ningún método de transmisión a través de internet o almacenamiento electrónico es completamente seguro. El uso de la aplicación está bajo su propio riesgo.</li>

      <p className={styles.subtitle}>Condiciones de Uso</p>
      <li className={styles.text}>Los usuarios deben tener al menos 8 años para utilizar la aplicación.</li>
      <li className={styles.text}>La información proporcionada por la aplicación no reemplaza el consejo médico profesional. Siempre consulte a un profesional de la salud para obtener diagnósticos y tratamientos médicos.</li>
      <li className={styles.text}>No se permite el uso inapropiado o ilegal de la aplicación, incluido el envío de contenido ofensivo o dañino.</li>

      <p className={styles.subtitle}>Cambios en la Política</p>
      <p className={styles.text}>Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Cualquier cambio será efectivo cuando se publique en la aplicación. Le recomendamos que revise periódicamente esta política para estar informado sobre cómo protegemos su información.</p>
      <p className={styles.text}>Si tiene preguntas sobre nuestra política de privacidad o cómo manejamos los datos del usuario, contáctenos en [dirección de correo electrónico de contacto].</p>

      <p className={styles.title}>Condiciones de Uso</p>
      <li className={styles.text}>Al utilizar nuestra aplicación, acepta cumplir con nuestras condiciones de uso.</li>
      <li className={styles.text}>No utilice la aplicación de manera que pueda dañarla, deshabilitarla, sobrecargarla o afectar negativamente su funcionamiento.</li>
      <li className={styles.text}>No intentará acceder a áreas no autorizadas de la aplicación, a otros sistemas o redes conectados a la aplicación, a través de la piratería, la extracción de contraseñas o cualquier otro medio ilegal.</li>
      <li className={styles.text}>Nos reservamos el derecho de suspender o cancelar su acceso a la aplicación si viola estas condiciones de uso.</li>
      <p></p>
      <text className={styles.text}>Al utilizar la aplicación, acepta estas condiciones de uso. Si no está de acuerdo con estas condiciones, le recomendamos no utilizar la aplicación.</text>
      <text className={styles.text}>Si tiene preguntas sobre nuestras condiciones de uso, contáctenos en [dirección de correo electrónico de contacto].</text>
      <text className={styles.text}>Este documento está sujeto a cambios sin previo aviso. Última actualización: [Fecha de la última actualización].</text>
      </div>
    </div>
  )
}
